import type { AppProps } from "next/app";
import Head from "next/head";
import "react-perfect-scrollbar/dist/css/styles.css";
import "tailwindcss/tailwind.css";
import "../styles/globals.scss";
// let FontFaceObserver = require('fontfaceobserver');

function App({ Component, pageProps }: AppProps) {
  // if (typeof window !== "undefined") {
  //   let fontA = new FontFaceObserver("Archivo Narrow");
  //   fontA.load().then(function () {
  //     //console.log('Archivo Narrow');
  //   });
  // }

  return (
    <>
      <Head>
        <title>IntuCharge</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default App;
